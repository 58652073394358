/* Import fonts */
@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('/assets/fonts/NeueHaasDisplay/NeueHaasDisplayLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('/assets/fonts/NeueHaasDisplay/NeueHaasDisplayRoman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasDisplay-Regular';
  src: url('/assets/fonts/NeueHaasDisplay/NeueHaasDisplayRoman.ttf') format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplay-Medium';
  src: url('/assets/fonts/NeueHaasDisplay/NeueHaasDisplayMediu.ttf') format('truetype');
}

@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('/assets/fonts/NeueHaasDisplay/NeueHaasDisplayMediu.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasDisplay';
  src: url('/assets/fonts/NeueHaasDisplay/NeueHaasDisplayBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
