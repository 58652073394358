/*--- MIXINS ---*/

// Heading Font Mixin
@mixin headingFont {
  font-size: $fontHeadingSize;
  font-weight: $fontHeadingWeight;
  line-height: $fontHeadingLineHeight;
}

/* Clear floating */
@mixin clearfix() {
  clear: both;
  content: '';
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

input::-ms-clear {
  display: none;
}

@mixin inputText() {
  background-color: $baseWhiteForeground;
  border: $borderS solid $baseWhiteBorderWhite;
  &:hover::placeholder {
    color: $baseBlackSecondaryGrey;
  }
  &:hover {
    border: $borderS solid $baseBlackIcons;
  }
  &:focus {
    box-shadow: 0 2px 4px 0 rgba($baseAccent, 32%), 0 0 0 4px rgba($baseAccent, 20%);
  }
  border-radius: $radiusM;
  color: $black;
  font-family: $fontBase;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  padding: 0 10px;
  width: 100%;
  &[disabled] {
    opacity: 0.6;
  }
}

@mixin closeButtonIconGenerator($iconColor, $xColor) {
  background-image: url('data:image/svg+xml;utf8,<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none"><path d="M20,10 C20,15.5235 15.5235,20 10,20 C4.4765,20 0,15.5235 0,10 C0,4.4765 4.4765,0 10,0 C15.5235,0 20,4.4765 20,10 Z" id="Fill-1" fill="#{$iconColor}"></path><path d="M6.4005,13.5995 L13.5995,6.4005 M6.4005,6.4005 L13.5995,13.5995" id="Stroke-4" stroke="#{$xColor}" stroke-width="1.6" stroke-linecap="round"></path></g></svg>');
}

@mixin closeButton($top, $bottom, $right, $margin, $iconColor, $xColor) {
  /* @include closeButtonIconGenerator($iconColor, $xColor);
    background-repeat: no-repeat;
    background-size: contain; */
  position: absolute;
  top: $top;
  bottom: $bottom;
  right: $right;
  width: 20px;
  height: 20px;
  margin: $margin;
  line-height: 22px;
  cursor: pointer;
}

@mixin icon($iconColor: $tertiaryColor) {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  cursor: pointer;
  color: $iconColor;
}
@mixin label {
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(60, 60, 60, 0.1);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin sidebarAnimation($propToAnimate) {
  transition: $propToAnimate cubic-bezier(0.4, 0, 0.2, 1) 0.25s;
}

@mixin tooltipShadow {
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.15);
}

@mixin cardShadow {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
}

@mixin onCardShadow {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

@mixin onCardShadowInset {
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

/* Multiselect item with checkbox */
@mixin multiselect-item() {
  padding: 0.6rem 1rem;
  border-bottom: #d6d6d4 solid 1px;
  width: 100%;

  label {
    display: flex;
    align-items: center;
    padding-left: 0px;

    &:before {
      position: static;
      margin-right: 10px;
    }

    &:after {
      margin: auto;
      position: absolute;
      top: 0;
      left: 7px;
      bottom: 4px;
    }

    eop-icon {
      vertical-align: middle;
    }
  }
}

@mixin multiselect-item-hover() {
  cursor: pointer;
  background-color: $secondaryColorDisabled;
}

@mixin multiselect-item-name() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // popover service sets width 400 with margin 15
  max-width: 320px;
  display: block;
}
