@font-face {
  font-family: 'icons_regular';
  src:  url('/assets/fonts/icons_regular/icons_regular.eot?86e70t');
  src:  url('/assets/fonts/icons_regular/icons_regular.eot?86e70t#iefix') format('embedded-opentype'),
    url('/assets/fonts/icons_regular/icons_regular.ttf?86e70t') format('truetype'),
    url('/assets/fonts/icons_regular/icons_regular.woff?86e70t') format('woff'),
    url('/assets/fonts/icons_regular/icons_regular.svg?86e70t#icons_regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-regular-"], [class*=" icon-regular-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons_regular' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-regular-cancel:before {
  content: "\e98e";
}
.icon-regular-resend:before {
  content: "\e98f";
}
.icon-regular-cog-settings-sync:before {
  content: "\e98d";
}
.icon-regular-performance-increase-fixed:before {
  content: "\e98a";
}
.icon-regular-performance-increase-absolute:before {
  content: "\e98b";
}
.icon-regular-performance-increase-relative:before {
  content: "\e98c";
}
.icon-regular-POI-dynamic:before {
  content: "\e986";
}
.icon-regular-POI-group:before {
  content: "\e987";
}
.icon-regular-POI-list:before {
  content: "\e988";
}
.icon-regular-POI-public:before {
  content: "\e989";
}
.icon-regular-computer-chip-core-add:before {
  content: "\e983";
}
.icon-regular-computer-chip-core-connect:before {
  content: "\e984";
}
.icon-regular-computer-chip-core:before {
  content: "\e985";
}
.icon-regular-filter1-add:before {
  content: "\e97f";
}
.icon-regular-filter-1:before {
  content: "\e980";
}
.icon-regular-book-flip-page:before {
  content: "\e982";
}
.icon-regular-clean-car-cog:before {
  content: "\e981";
}
.icon-regular-credit-card-give:before {
  content: "\e97e";
}
.icon-regular-clean-car-communication:before {
  content: "\e97d";
}
.icon-regular-server-choose:before {
  content: "\e97c";
}
.icon-regular-electric-car-cable-pilotsignal:before {
  content: "\e979";
}
.icon-regular-electric-car-cable-plugcharge:before {
  content: "\e97a";
}
.icon-regular-clean-car-accu:before {
  content: "\e97b";
}
.icon-regular-phone-type:before {
  content: "\e978";
}
.icon-regular-light-bulb-shine:before {
  content: "\e976";
}
.icon-regular-expand-6:before {
  content: "\e977";
}
.icon-regular-renewable-energy-battery-charge-1:before {
  content: "\e974";
}
.icon-regular-add-circle:before {
  content: "\e975";
}
.icon-regular-wireless-payment-smartphone-1:before {
  content: "\e965";
}
.icon-regular-wifi-lock:before {
  content: "\e966";
}
.icon-regular-wall-socket:before {
  content: "\e967";
}
.icon-regular-office-outdoors:before {
  content: "\e968";
}
.icon-regular-monitor:before {
  content: "\e969";
}
.icon-regular-mobile-phone:before {
  content: "\e96a";
}
.icon-regular-flash-drive:before {
  content: "\e96b";
}
.icon-regular-electric-car-cable:before {
  content: "\e96c";
}
.icon-regular-earth-1:before {
  content: "\e96d";
}
.icon-regular-credit-card-1:before {
  content: "\e96e";
}
.icon-regular-clean-car-ok:before {
  content: "\e96f";
}
.icon-regular-button-play:before {
  content: "\e970";
}
.icon-regular-single-neutral-actions-add:before {
  content: "\e971";
}
.icon-regular-folder-dash-edit:before {
  content: "\e972";
}
.icon-regular-flag-cash:before {
  content: "\e973";
}
.icon-regular-multiple-neutral-1:before {
  content: "\e964";
}
.icon-regular-wifi-add:before {
  content: "\e900";
}
.icon-regular-view-off:before {
  content: "\e901";
}
.icon-regular-view-1:before {
  content: "\e902";
}
.icon-regular-Unlock:before {
  content: "\e903";
}
.icon-regular-time-clock-circle:before {
  content: "\e904";
}
.icon-regular-text-underline:before {
  content: "\e905";
}
.icon-regular-text-italic:before {
  content: "\e906";
}
.icon-regular-text-bold:before {
  content: "\e907";
}
.icon-regular-synchronize-arrow:before {
  content: "\e908";
}
.icon-regular-synchronize-arrow-select:before {
  content: "\e909";
}
.icon-regular-stop-charging-battery:before {
  content: "\e90a";
}
.icon-regular-smart-house-open:before {
  content: "\e90b";
}
.icon-regular-single-neutral-actions:before {
  content: "\e90c";
}
.icon-regular-shield-lock:before {
  content: "\e90d";
}
.icon-regular-settings-slider:before {
  content: "\e90e";
}
.icon-regular-search:before {
  content: "\e90f";
}
.icon-regular-question-circle:before {
  content: "\e910";
}
.icon-regular-qr-code-scan:before {
  content: "\e911";
}
.icon-regular-power-button:before {
  content: "\e912";
}
.icon-regular-pin:before {
  content: "\e913";
}
.icon-regular-pin-add:before {
  content: "\e914";
}
.icon-regular-pencil-1:before {
  content: "\e915";
}
.icon-regular-Paste:before {
  content: "\e916";
}
.icon-regular-password-update:before {
  content: "\e917";
}
.icon-regular-paragraph-right-align:before {
  content: "\e918";
}
.icon-regular-paragraph-left-align:before {
  content: "\e919";
}
.icon-regular-paragraph-center-align:before {
  content: "\e91a";
}
.icon-regular-paginate-filter-text:before {
  content: "\e91b";
}
.icon-regular-paginate-filter-plus:before {
  content: "\e91c";
}
.icon-regular-notes-add:before {
  content: "\e91d";
}
.icon-regular-network-settings:before {
  content: "\e91e";
}
.icon-regular-navigation-menu:before {
  content: "\e91f";
}
.icon-regular-monitor-graph-line:before {
  content: "\e920";
}
.icon-regular-monetization-touch-coin:before {
  content: "\e921";
}
.icon-regular-messages-bubble-add:before {
  content: "\e922";
}
.icon-regular-maps-pin-1:before {
  content: "\e923";
}
.icon-regular-logout:before {
  content: "\e924";
}
.icon-regular-login-keys:before {
  content: "\e925";
}
.icon-regular-login-3:before {
  content: "\e926";
}
.icon-regular-list-edit:before {
  content: "\e927";
}
.icon-regular-legal-scale:before {
  content: "\e928";
}
.icon-regular-legal-certificate:before {
  content: "\e929";
}
.icon-regular-legal-certificate-add:before {
  content: "\e92a";
}
.icon-regular-layout-module-1:before {
  content: "\e92b";
}
.icon-regular-laptop-help-message:before {
  content: "\e92c";
}
.icon-regular-italic-off:before {
  content: "\e92d";
}
.icon-regular-information-circle:before {
  content: "\e92e";
}
.icon-regular-headphones-customer-support-human:before {
  content: "\e92f";
}
.icon-regular-grid-ruler:before {
  content: "\e930";
}
.icon-regular-gauge-dashboard:before {
  content: "\e931";
}
.icon-regular-gauge-dashboard-1:before {
  content: "\e932";
}
.icon-regular-folder-download:before {
  content: "\e933";
}
.icon-regular-ecology-globe-hand:before {
  content: "\e934";
}
.icon-regular-download-bottom:before {
  content: "\e935";
}
.icon-regular-delete:before {
  content: "\e936";
}
.icon-regular-delete-select:before {
  content: "\e937";
}
.icon-regular-database-share_1:before {
  content: "\e938";
}
.icon-regular-database-settings:before {
  content: "\e939";
}
.icon-regular-database-refresh:before {
  content: "\e93a";
}
.icon-regular-database-lock:before {
  content: "\e93b";
}
.icon-regular-database-add:before {
  content: "\e93c";
}
.icon-regular-data-file-edit:before {
  content: "\e93d";
}
.icon-regular-currency-dollar-bill:before {
  content: "\e93e";
}
.icon-regular-currency-dollar-bill-add:before {
  content: "\e93f";
}
.icon-regular-credit-card-scan:before {
  content: "\e940";
}
.icon-regular-credit-card-dollar-1:before {
  content: "\e941";
}
.icon-regular-compass-1:before {
  content: "\e942";
}
.icon-regular-common-file-text-download:before {
  content: "\e943";
}
.icon-regular-cog:before {
  content: "\e944";
}
.icon-regular-cog-hand-give:before {
  content: "\e945";
}
.icon-regular-cog-hand-give-add:before {
  content: "\e946";
}
.icon-regular-cog-3-add:before {
  content: "\e947";
}
.icon-regular-close:before {
  content: "\e948";
}
.icon-regular-clean-car-pay:before {
  content: "\e949";
}
.icon-regular-clean-car-gas:before {
  content: "\e94a";
}
.icon-regular-check-double:before {
  content: "\e94b";
}
.icon-regular-charging-flash-sync:before {
  content: "\e94c";
}
.icon-regular-charging-battery-empty:before {
  content: "\e94d";
}
.icon-regular-calendar-3:before {
  content: "\e94e";
}
.icon-regular-business-deal-handshake:before {
  content: "\e94f";
}
.icon-regular-business-contract-handshake-sign:before {
  content: "\e950";
}
.icon-regular-brain-lightning:before {
  content: "\e951";
}
.icon-regular-bookmarks-document:before {
  content: "\e952";
}
.icon-regular-book-close-bookmark-1:before {
  content: "\e953";
}
.icon-regular-bin-1:before {
  content: "\e954";
}
.icon-regular-arrow-up-1:before {
  content: "\e955";
}
.icon-regular-arrow-right:before {
  content: "\e956";
}
.icon-regular-arrow-right-end:before {
  content: "\e957";
}
.icon-regular-arrow-right-1:before {
  content: "\e958";
}
.icon-regular-arrow-left:before {
  content: "\e959";
}
.icon-regular-arrow-left-end:before {
  content: "\e95a";
}
.icon-regular-arrow-left-1:before {
  content: "\e95b";
}
.icon-regular-arrow-down-1:before {
  content: "\e95c";
}
.icon-regular-analytics-graph-lines:before {
  content: "\e95d";
}
.icon-regular-analytics-graph-bar:before {
  content: "\e95e";
}
.icon-regular-alert-triangle:before {
  content: "\e95f";
}
.icon-regular-alarm-bell:before {
  content: "\e960";
}
.icon-regular-accounting-document:before {
  content: "\e961";
}
.icon-regular-accounting-document-add:before {
  content: "\e962";
}
.icon-regular-ab-testing-browsers:before {
  content: "\e963";
}
