/** Grid */
[class*='col-'] {
  float: left;
  min-height: 1px;
  padding: 0 10px;
  [class*='col-'] {
    padding: 0;
  }
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

/*** Clearfix */
.clearfix:after {
  @include clearfix();
}

.clearfix {
  clear: both;
}

/** Padding Form Element **/
.padding_left_form {
  padding-left: 30px;
}

.padding_right_form {
  padding-right: 30px;
}

/** Margin Form Element **/
.margin_top_0 {
  margin-top: 0px !important;
}

.flex-container {
  display: flex;
  &--direction-column {
    flex-flow: column;
  }
  &--align-bottom {
    align-items: flex-end;
  }
  &--align-center {
    align-items: center;
  }
  &--justify-center {
    justify-content: center;
  }
  &--wrap {
    flex-flow: wrap;
  }
}
