table {
  color: $baseBlack;
  font-size: 14px;
  &.light {
    color: $white;
  }
}
// Table layout
div.tbl {
  display: table;
  width: 100%;
  div.caption {
    display: table-caption;
  }
  div.tr {
    display: table-row;
    div.th,
    div.td {
      display: table-cell;
    }
  }
  &.fixed {
    table-layout: fixed;
  }
}
