button {
  font-family: $fontBase;

  span {
    font-weight: 600;
    font-size: 16px;
  }
}

/**
    Buttons
*/

/** Info Button */
@mixin infoIconGenerator($color) {
  background-image: url('data:image/svg+xml;utf8,<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Icons/i-info" fill="#{$color}"><path d="M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z M11.25,9.05000001 C11.25,8.60817221 10.8918278,8.25 10.45,8.25 L9.55000001,8.25 C9.10817221,8.25 8.75,8.60817221 8.75,9.05000001 L8.75,15.45 C8.75,15.8918278 9.10817221,16.25 9.55000001,16.25 L10.45,16.25 C10.8918278,16.25 11.25,15.8918278 11.25,15.45 L11.25,9.05000001 Z M8.75,5.25 C8.75,5.94035594 9.30964406,6.5 10,6.5 C10.6903559,6.5 11.25,5.94035594 11.25,5.25 C11.25,4.55964406 10.6903559,4 10,4 C9.30964406,4 8.75,4.55964406 8.75,5.25 Z" id="Combined-Shape"></path></g></svg>');
}

a.info {
  color: $infoColor;
  display: inline-block;
  padding-left: 31px;
  position: relative;
  text-align: center;
  &:before {
    @include infoIconGenerator($infoColor);
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    width: 21px;
    height: 21px;
    line-height: 21px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  &.disabled {
    color: $tertiaryColor;
    cursor: default;
    &:before {
      @include infoIconGenerator($tertiaryColor);
    }
  }
  &.negative {
    color: $white;
    &:before {
      @include infoIconGenerator($white);
    }
  }
  &:not(.disabled):hover {
    color: $secondaryColorHover;
    &:before {
      @include infoIconGenerator($secondaryColorHover);
    }
  }
  &.negative:hover {
    color: $white;
    text-decoration: underline;
  }
}
