.close-icon {
  height: 20px;
  width: 20px;
  display: block;
  float: right;
  right: 11px;
  top: 11px;
  cursor: pointer;
}

.times-close {
  position: absolute;
  right: 50px;
  cursor: pointer;
  font-size: 50px;
  color: currentColor;
  width: 24px;
  height: 24px;
}
