/**
  Color palette
*/

// Base White
$baseWhiteForeground: #ffffff;
$baseWhiteOnForeground: #f8f8fc;
$baseWhiteBackground: #f1f1fa;
$baseWhiteBorderWhite: #dadaf1;
$baseWhiteElements: #b9b9d4;

// Base Black
$baseBlack: #232325;
$baseBlackPrimaryGrey: #4e4e5f;
$baseBlackSecondaryGrey: #606076;
$baseBlackIcons: #7e7e95;
$baseBlackElements: $baseWhiteElements;

// Base Accent
$baseAccentText: #1d2d9a;
$baseAccent: #475eff;
$baseAccentIconography: #6679ff;
$baseAccentColoredBorders: #c2c9ff;
$baseAccentSelectedHover: #e0e4ff;
$baseAccentBackgrounds: #f5f6ff;

// Base Error
$baseErrorText: #891a27;
$baseError: #ff334b;
$baseErrorIconography: #ff5266;
$baseErrorColoredBorders: #ffadb7;
$baseErrorSelectedHover: #ffccd2;
$baseErrorBackgrounds: #ffe0e4;

// Base Warning
$baseWarningText: #89491a;
$baseWarning: #ff8933;
$baseWarningIconography: #ff9a52;
$baseWarningColoredBorders: #ffd0ad;
$baseWarningSelectedHover: #ffe1cc;
$baseWarningBackgrounds: #ffede0;

// Base Success
$baseSuccessText: #263c25;
$baseSuccess: #39bd35;
$baseSuccessIconography: #48cb44;
$baseSuccessColoredBorders: #8edf8b;
$baseSuccessSelectedHover: #c5eec3;
$baseSuccessBackgrounds: #e8f8e7;

// Base Info
$baseInfoText: #1c464f;
$baseInfo: #1bbde0;
$baseInfoIconography: #32c6e6;
$baseInfoColoredBorders: #84ddf0;
$baseInfoSelectedHover: #c4eff8;
$baseInfoBackgrounds: #d6f4fa;

// Base Default
$baseDefaultText: $baseBlackPrimaryGrey;
$baseDefault: $baseBlackPrimaryGrey;
$baseDefaultIconography: $baseBlackIcons;
$baseDefaultColoredBorders: $baseWhiteBorderWhite;
// $baseDefaultSelectedHover: #fff;
$baseDefaultBackgrounds: $baseWhiteBackground;
$baseGrayBackgrounds: #f5f5f5;
