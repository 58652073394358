.eop-digits-crop--text-holder {
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  vertical-align: inherit;
  cursor: inherit;
}

.eop-digits-crop_input-temp {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
}

.inputElement {
  .ng-touched.ng-valid[required],
  .ng-touched.ng-valid.required {
    border-bottom: 1px solid $successColor; /* green */
  }

  .ng-touched.ng-invalid:not(form) {
    border-bottom: 1px solid $errorColor; /* red */
  }
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(60, 55, 50, 0.5);
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(60, 55, 50, 0.5);
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(60, 55, 50, 0.5);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(60, 55, 50, 0.5);
}

/** Input Type Number **/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
