.app-container {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 100vh;
  .top-notification-margin {
    margin-bottom: $topNotificationHeight;
  }
  .container-fluid {
    margin: 0 24px;
    position: relative;
    padding-left: $menuExpandedWidth;
    box-sizing: content-box;
    @include sidebarAnimation(padding);
    @media only screen and (max-width: $screenWidthDesktopMax) {
      padding-left: $menuCollapsedWidth + 10px;
    }
    > .app-content {
      width: $contentWidthForDesktopMax;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      z-index: 10;
      padding-top: 24px;
      padding-bottom: 20px;
      height: 100%;
      &.full-width {
        width: auto;
      }
    }
    &.no-sidebar-menu {
      padding: 0 $pagePadding;
      @media only screen and (max-width: $screenWidthDesktopMax) {
        padding: 0 $pagePadding;
      }
    }
    &.sidebar-collapsed {
      padding-left: $menuCollapsedWidth + 10px;
    }
  }
  &.login-screen {
    height: 100%;
    padding-bottom: 0;
  }
  &.login-screen {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-bottom: 0;
    overflow: inherit;
    .container-fluid {
      height: 100%;
      > .app-content {
        height: 100%;
        padding: 0;
      }
    }
  }
  &.homepage .container-fluid {
    height: calc(100% - 35px);
    > .app-content {
      height: calc(100% - 168px);
      padding-top: 0;
    }
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.emob-dark-backdrop {
    background: #000;
    opacity: 0.4;
  }
}

.emob-modal-background-blur {
  filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 1000;
  width: 100%;
  height: 100vh;
  position: fixed;
}

@media only screen and (max-width: $screenWidthDesktopSmallMax) {
  .app-container .container-fluid > .app-content {
    width: $contentWidthForDesktopSmall;
  }
}

@media only screen and (max-width: $screenWidthTabletMax) {
  .app-container .container-fluid > .app-content {
    width: $contentWidthForTabletMax;
  }
}
