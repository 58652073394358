html {
  height: 100%;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  /* 
  TODO: Check if needed after upgrade material and angular - delete modal windows
  (Ticket 61053, Ticket 61057)
  */
  top: 0 !important;
}

html,
html a {
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  /* innogy wants to have text selection (Ticket 747)
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;*/
  cursor: default;
  overflow-y: scroll;
}

body *,
body *:after,
body *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  background: transparent;
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img,
svg {
  display: block;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: visible;
}

figure {
  margin: 0;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

address {
  font-style: normal;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  -webkit-appearance: none;
}

button,
input[type='button'] {
  background: none;
  color: initial;
  overflow: visible;
  border: 0;
  -webkit-appearance: none;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  border: 0;
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

input[type='date']::-webkit-datetime-edit,
input[type='date']::-webkit-datetime-edit-fields-wrapper,
input[type='date']::-webkit-datetime-edit-text,
input[type='date']::-webkit-datetime-edit-month-field,
input[type='date']::-webkit-datetime-edit-day-field,
input[type='date']::-webkit-datetime-edit-year-field,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  border: 0;
  resize: none;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

p,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}
