@font-face {
  font-family: 'icons_bold';
  src:  url('/assets/fonts/icons_bold/icons_bold.eot?2zdl3p');
  src:  url('/assets/fonts/icons_bold/icons_bold.eot?2zdl3p#iefix') format('embedded-opentype'),
    url('/assets/fonts/icons_bold/icons_bold.ttf?2zdl3p') format('truetype'),
    url('/assets/fonts/icons_bold/icons_bold.woff?2zdl3p') format('woff'),
    url('/assets/fonts/icons_bold/icons_bold.svg?2zdl3p#icons_bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-bold-"], [class*=" icon-bold-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons_bold' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bold-pin-undefined:before {
  content: "\e900";
}
.icon-bold-pin-unavailble:before {
  content: "\e901";
}
.icon-bold-pin-reserved:before {
  content: "\e902";
}
.icon-bold-pin-preparing:before {
  content: "\e903";
}
.icon-bold-pin-operative:before {
  content: "\e904";
}
.icon-bold-pin-occupied:before {
  content: "\e905";
}
.icon-bold-pin-noHeartbeat:before {
  content: "\e906";
}
.icon-bold-pin-no-response:before {
  content: "\e907";
}
.icon-bold-pin-new:before {
  content: "\e908";
}
.icon-bold-pin-warning:before {
  content: "\e909";
}
.icon-bold-pin-unreachable:before {
  content: "\e90a";
}
.icon-bold-pin-unknown-error:before {
  content: "\e90b";
}
.icon-bold-pin-suspended:before {
  content: "\e90c";
}
.icon-bold-pin-success:before {
  content: "\e90d";
}
.icon-bold-pin-invalid-response:before {
  content: "\e90e";
}
.icon-bold-pin-inoperative:before {
  content: "\e90f";
}
.icon-bold-pin-finishing:before {
  content: "\e910";
}
.icon-bold-pin-faulted:before {
  content: "\e911";
}
.icon-bold-pin-error:before {
  content: "\e912";
}
.icon-bold-pin-disabled:before {
  content: "\e913";
}
.icon-bold-pin-charge:before {
  content: "\e914";
}
.icon-bold-Type-B:before {
  content: "\e915";
}
.icon-bold-wench-double:before {
  content: "\e916";
}
.icon-bold-Type-UNKNOWN:before {
  content: "\e917";
}
.icon-bold-Type-C:before {
  content: "\e918";
}
.icon-bold-Type-A:before {
  content: "\e919";
}
.icon-bold-touch-id-1:before {
  content: "\e91a";
}
.icon-bold-Status:before {
  content: "\e91b";
}
.icon-bold-settings-slider:before {
  content: "\e91c";
}
.icon-bold-renewable-energy-battery-charge:before {
  content: "\e91d";
}
.icon-bold-remove-circle:before {
  content: "\e91e";
}
.icon-bold-pin:before {
  content: "\e91f";
}
.icon-bold-pin-location-1:before {
  content: "\e920";
}
.icon-bold-paginate-filter-text:before {
  content: "\e921";
}
.icon-bold-os-system-windows:before {
  content: "\e922";
}
.icon-bold-os-system-apple:before {
  content: "\e923";
}
.icon-bold-notes-book-text-alternate:before {
  content: "\e924";
}
.icon-bold-navigation-menu-horizontal:before {
  content: "\e925";
}
.icon-bold-lock-unlock-1:before {
  content: "\e926";
}
.icon-bold-lock-2:before {
  content: "\e927";
}
.icon-bold-list-bullets:before {
  content: "\e928";
}
.icon-bold-legal-certificate:before {
  content: "\e929";
}
.icon-bold-innogy:before {
  content: "\e92a";
}
.icon-bold-information-circle:before {
  content: "\e92b";
}
.icon-bold-hubject:before {
  content: "\e92c";
  color: #7ab4c1;
}
.icon-bold-gauge-dashboard:before {
  content: "\e92d";
}
.icon-bold-earth-1:before {
  content: "\e92e";
}
.icon-bold-DC:before {
  content: "\e92f";
}
.icon-bold-single-neutral:before {
  content: "\e930";
}
.icon-bold-add-bold:before {
  content: "\e931";
}
.icon-bold-subtract-bold:before {
  content: "\e932";
}
.icon-bold-clean-car-gas:before {
  content: "\e933";
}
.icon-bold-calendar-3:before {
  content: "\e934";
}
.icon-bold-wench-double-cutted:before {
  content: "\e935";
}
.icon-bold-information-circle-cutted:before {
  content: "\e936";
}
.icon-bold-clean-car-gas-cutted:before {
  content: "\e937";
}
.icon-bold-charger-cutted:before {
  content: "\e938";
}
.icon-bold-brain-lightning-bold-cutted:before {
  content: "\e939";
}
.icon-bold-lock-shield:before {
  content: "\e93a";
}
.icon-bold-headphones-customer-support-human:before {
  content: "\e93b";
}
.icon-bold-bookmarks-document:before {
  content: "\e93c";
}
.icon-bold-credit-card-1:before {
  content: "\e93d";
}
.icon-bold-electric-car-cable:before {
  content: "\e93e";
}
.icon-bold-mobile-phone:before {
  content: "\e93f";
}
.icon-bold-monitor:before {
  content: "\e940";
}
.icon-bold-phone-type:before {
  content: "\e941";
}
.icon-bold-qr-code-scan:before {
  content: "\e942";
}
.icon-bold-wireless-payment-smartphone-1:before {
  content: "\e943";
}
.icon-bold-e-clearing:before {
  content: "\e944";
}
.icon-bold-gireve:before {
  content: "\e945";
}
.icon-bold-OCPI:before {
  content: "\e946";
}
.icon-bold-currency-dollar-bill:before {
  content: "\e947";
}
.icon-bold-question-help-circle:before {
  content: "\e948";
}
.icon-bold-performance-money-increase:before {
  content: "\e949";
}
.icon-bold-check-badge:before {
  content: "\e94a";
}
.icon-bold-diet-scale:before {
  content: "\e94b";
}
.icon-bold-wifi-lock:before {
  content: "\e94c";
}
.icon-bold-electric-car-cable-plugcharge:before {
  content: "\e94d";
}
.icon-bold-electric-car-cable-pilotsignal:before {
  content: "\e94e";
}
.icon-bold-credit-card-give:before {
  content: "\e94f";
}
.icon-bold-pin-station:before {
  content: "\e950";
}
.icon-bold-synchronize-arrows-square-warning:before {
  content: "\e951";
}
.icon-bold-wifi-off:before {
  content: "\e952";
}
.icon-bold-ranking-winner-badge-1:before {
  content: "\e953";
}
.icon-bold-ranking-winner-badge-2:before {
  content: "\e954";
}
.icon-bold-ranking-winner-badge-3:before {
  content: "\e955";
}
.icon-bold-database-share_1:before {
  content: "\e956";
}
.icon-bold-compass-1:before {
  content: "\e957";
}
.icon-bold-common-file-text:before {
  content: "\e958";
}
.icon-bold-cog-hand-give:before {
  content: "\e959";
}
.icon-bold-cog-1:before {
  content: "\e95a";
}
.icon-bold-close:before {
  content: "\e95b";
}
.icon-bold-charging-battery-empty:before {
  content: "\e95c";
}
.icon-bold-charger:before {
  content: "\e95d";
}
.icon-bold-button-refresh-arrow:before {
  content: "\e95e";
}
.icon-bold-building-modern-2:before {
  content: "\e95f";
}
.icon-bold-brain-lightning-bold:before {
  content: "\e960";
}
.icon-bold-arrow-up-2:before {
  content: "\e961";
}
.icon-bold-arrow-left-x:before {
  content: "\e962";
}
.icon-bold-arrow-down-2:before {
  content: "\e963";
}
.icon-bold-arrow-button-up-2:before {
  content: "\e964";
}
.icon-bold-arrow-button-down-2:before {
  content: "\e965";
}
.icon-bold-analytics-graph-bar:before {
  content: "\e966";
}
.icon-bold-alert-triangle:before {
  content: "\e967";
}
.icon-bold-add:before {
  content: "\e968";
}
.icon-bold-AC:before {
  content: "\e969";
}
.icon-bold-ab-testing-browsers:before {
  content: "\e96a";
}
.icon-bold-filter-2:before {
  content: "\e96b";
}
.icon-bold-arrow-thick-circle-left-2:before {
  content: "\e96c";
}
.icon-bold-arrow-thick-circle-right-2:before {
  content: "\e96d";
}
.icon-bold-cee:before {
  content: "\e96e";
}
.icon-bold-type-1:before {
  content: "\e96f";
}
.icon-bold-powerlock:before {
  content: "\e970";
}
.icon-bold-computer-chip-core-1:before {
  content: "\e971";
}
.icon-bold-pin-invalid-response-big:before {
  content: "\e972";
}
.icon-bold-pin-terminal:before {
  content: "\e973";
}
.icon-bold-update-cog:before {
  content: "\e974";
}
.icon-bold-Status-outline:before {
  content: "\e975";
}
.icon-bold-tools-wench:before {
  content: "\e976";
}
.icon-bold-signal-cellular:before {
  content: "\e977";
}
