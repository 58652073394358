/**
  Base variables
*/

/*** Base Paddings ***/
$pagePadding: 40px;
$gridPadding: 10px;
$titleMargin: 20px;
$topNotificationHeight: 69px;
$menuCollapsedWidth: 56px;
$menuExpandedWidth: 265px;

$radiusXS: 2px;
$radiusS: 4px;
$radiusM: 8px;
$radiusL: 12px;
$radiusXL: 16px;

$borderS: 1px;
$borderM: 2px;

/**
  Buttons variables
*/

// Toggle button
$toggleButtonPrimaryBorderColor: $baseAccent;
$toggleButtonPrimaryInactiveColor: $baseAccent;
$toggleButtonPrimaryInactiveBackgroundColor: transparent;
$toggleButtonPrimaryActiveColor: $baseWhiteForeground;
$toggleButtonPrimaryActiveBackgroundColor: $baseAccent;
$toggleButtonSecondaryBorderColor: $baseAccentColoredBorders;
$toggleButtonSecondaryInactiveColor: $baseAccentText;
$toggleButtonSecondaryInactiveBackgroundColor: transparent;
$toggleButtonSecondaryActiveColor: $baseAccentText;
$toggleButtonSecondaryActiveBackgroundColor: $baseAccentColoredBorders;

// Toggle switch
