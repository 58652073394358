@import 'mixins';
@import 'vaylens/palette';
@import 'vaylens/variables';

/*** Base Colors ***/
$backgroundPrimaryColor: $baseWhiteBackground;
$backgroundWhiteColor: $baseWhiteForeground;

$borderColor: $baseWhiteBorderWhite;
$borderColorHover: $baseBlackIcons;

$fontColor: $baseBlack;

// mainly used for buttons
// primaryColor
$primaryColor: $baseAccent;
$primaryColorHover: $baseAccentIconography;
$primaryColorDisabled: rgba($primaryColor, 30%);
$onPrimaryColor: $baseWhiteForeground;
$onPrimaryActiveColor: $baseWhiteBorderWhite;
// secondaryColor
$secondaryColor: $baseAccentSelectedHover;
$secondaryColorHover: $baseAccentColoredBorders;
$secondaryColorDisabled: rgba($secondaryColor, 30%);
$onSecondaryColor: $baseAccentText;
$onSecondaryActiveColor: $baseAccentIconography;
// tertiaryColor
$tertiaryColor: $baseWhiteBackground;
$tertiaryColorHover: $baseWhiteBorderWhite;
$tertiaryColorDisabled: rgba($tertiaryColor, 30%);
$onTertiaryColor: $baseBlack;
$onTertiaryActiveColor: $baseBlackPrimaryGrey;
// mainly used for chips
// defaultColor
$defaultColor: $baseBlackPrimaryGrey;
$defaultColorDisabled: rgba($defaultColor, 30%);
$defaultColorLight: $baseWhiteBackground;
// errorColor
$errorColor: $baseError;
$errorColorDisabled: rgba($errorColor, 30%);
$errorColorLight: $baseErrorBackgrounds;
// warningColor
$warningColor: $baseWarning;
$warningColorDisabled: rgba($warningColor, 30%);
$warningColorLight: $baseWarningBackgrounds;
// successColor
$successColor: $baseSuccess;
$successColorDisabled: rgba($successColor, 30%);
$successColorLight: $baseSuccessBackgrounds;
// infoColor
$infoColor: $baseInfo;
$infoColorDisabled: rgba($infoColor, 30%);
$infoColorLight: $baseInfoBackgrounds;
// do not use these colors anymore

$white: #ffffff;
$black: #000000;
$gray: rgba(60, 60, 60, 0.8);

/*** Fonts ***/

/* Basic */
$fontBase: 'NeueHaasDisplay', Arial, sans-serif;

$lightest: 300;
$boldest: 800;

/* Heading Font*/
$fontHeadingSize: 16px;
$fontHeadingWeight: bold;
$fontHeadingLineHeight: 27px;

/*** Screen Sizes ***/
$screenWidthDesktopMax: 1620px;
$screenWidthDesktopSmallMax: 1480px;
$screenWidthTabletMax: 1280px;
/*** Content Width ***/
$contentWidthForDesktopMax: 1380px;
$contentWidthForDesktopSmall: 1140px;
$contentWidthForTabletMax: 940px;

/*** Z-Index Global Levels ***/
// To be used to define overlay, messages, notifications and all other global elements
// important to know: cdk-overlay generates one more div which is outside app-root

$z-level-1: 1;
$z-level-2: 5;
$z-level-3: 10;
$z-level-4: 20;
$z-level-5: 100;
$z-level-6: 200;
$z-level-7: 500;
$z-level-8: 600;
$z-level-9: 700;
$z-level-10: 800;
$z-level-11: 900;
$z-level-12: 1000; // Default z-index used by material overlays
$z-level-13: 2000;
$z-level-14: 9000;

/*** z-index levels assignments ***/
$cdk-overlay-pane-level: $z-level-12;
$cdk-notification-overlay-level: $z-level-13;
$innogy-dropdown-component-level: $z-level-14;
