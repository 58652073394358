/** Headers and Texts */

h1 {
  color: $baseBlack;
  font-size: 37px;
  font-weight: bold;
  line-height: 57px;

  &.light {
    color: $white;
  }
}

h2 {
  color: $baseBlack;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;

  &.light {
    color: $white;
  }
}

h3 {
  color: $baseBlack;
  font-size: 19px;
  font-weight: 800;
  line-height: 39px;

  &.light {
    color: $white;
  }
}

h4 {
  color: $baseBlack;
  font-weight: 800;
  font-size: 16px;
  line-height: 37px;

  &.light {
    color: $white;
  }
}
pre {
  font-family: $fontBase;
  color: $black;
  font-size: 16px;
  font-weight: normal;

  &.light {
    color: $white;
  }
}

a,
.clickable {
  cursor: pointer;
  color: $baseAccentText;
  font-weight: unset;
  text-transform: none;
  z-index: 5;

  &.light {
    color: $white;
  }
}

.disabled {
  cursor: not-allowed;
  color: $baseBlack;
  opacity: 0.5;
  z-index: 5;
}

.close {
  cursor: pointer;
  color: $black;
  z-index: 5;
}

small,
.small {
  font-size: 9px;
  font-weight: unset;

  &.light {
    color: $white;
  }
}

// label,
small {
  color: $black;
  font-size: 13px;
  font-weight: normal;

  &.bold {
    font-weight: bold;
  }

  &.light {
    color: $white;
  }
}

/** Texts */
textarea,
input[type='text']:not(.ag-text-field-input),
input[type='number']:not(.ag-number-field-input),
input[type='email'],
input[type='password'] {
  @include inputText();
  &.success {
    background-image: url('/img/input_success.png');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  &.error {
    border: $borderS solid $errorColor;
    color: $baseBlack;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $baseBlack;
      opacity: 0.6;
    }
  }
  &.negative {
    border: 1px solid $white;
    background-color: $gray;
    color: $white;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $white;
      opacity: 0.6;
    }
  }
  + .error-msg {
    display: none;
    visibility: hidden;
    background-color: $baseErrorText;
    padding: 6px 26px 4px 10px;
    position: absolute;
    right: 0;
    bottom: 60px;
    width: 100%;
    min-width: 260px;
    .close-btn {
      @include closeButton(6px, auto, 8px, 0, $white, $errorColor);
    }
    .text {
      color: $white;
      font-size: 15px;
      line-height: 23px;
      max-width: calc(100% - 20px);
    }
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      right: 10px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 14px solid $errorColor;
    }
  }
  &.error:focus + .error-msg {
    display: block;
    visibility: visible;
  }
  &:not(.error):focus:not(.negative):focus {
    border: $borderS solid $tertiaryColor;
  }
  + label {
    margin-top: 22px;
  }
  + button.btn,
  input[type='button'].btn,
  a.btn {
    margin-top: 36px;
  }
}

/** Textarea */
textarea {
  @include inputText();
  display: block;
  padding: 10px;
  + button.btn,
  input[type='button'].btn,
  a.btn {
    margin-top: 36px;
  }
  &.error {
    border: $borderS solid $errorColor;
    background-color: $gray;
    color: $baseBlack;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $baseBlack;
      opacity: 0.6;
    }
  }
}

.text-highlighted {
  background-color: $primaryColorDisabled;
}
