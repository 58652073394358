.box-shadow-1 {
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}

.box-shadow-tool-tip {
  -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.15);
}

.box-shadow-3 {
  box-shadow: 0 6px 10px -1px rgba(0, 0, 0, 0.2);
}

.on-card-box-shadow {
  @include onCardShadow();
}

.on-card-box-shadow--inset {
  @include onCardShadowInset();
}

.card-box-shadow {
  @include cardShadow();
}

.tooltip-box-shadow {
  @include tooltipShadow();
}
