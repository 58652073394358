/** Notifications */
.notification {
  background-color: $gray;
  color: $white;
  line-height: 23px;
  min-height: 35px;
  margin: 0 auto;
  padding: 8px 10px 4px 14px;
  width: 100%;
  border-radius: $radiusL;
  border-width: 1px;
  .close-btn {
    @include closeButton(8px, auto, 14px, 0, $white, $gray);
  }
  &.success {
    background-color: $successColor;
    .close-btn {
      @include closeButton(8px, auto, 14px, 0, $white, $successColor);
    }
  }
  &.error {
    background-color: $errorColor;
    .close-btn {
      @include closeButton(8px, auto, 14px, 0, $white, $errorColor);
    }
  }
}
