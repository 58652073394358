@font-face {
  font-family: 'ePowerDirect';
  src:  url('/assets/fonts/ePowerDirect/ePowerDirect.eot?62qywg');
  src:  url('/assets/fonts/ePowerDirect/ePowerDirect.eot?62qywg#iefix') format('embedded-opentype'),
  url('/assets/fonts/ePowerDirect/ePowerDirect.woff2?62qywg') format('woff2'),
  url('/assets/fonts/ePowerDirect/ePowerDirect.ttf?62qywg') format('truetype'),
  url('/assets/fonts/ePowerDirect/ePowerDirect.woff?62qywg') format('woff'),
  url('/assets/fonts/ePowerDirect/ePowerDirect.svg?62qywg#ePowerDirect') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-regular-epd-"], [class*=" icon-regular-epd-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ePowerDirect' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-regular-epd-epowerdirect-type-2:before {
  content: "\e900";
}
.icon-regular-epd-chademo:before {
  content: "\e901";
}
.icon-regular-epd-ccs:before {
  content: "\e902";
}
.icon-regular-epd-menu:before {
  content: "\e903";
}
.icon-regular-epd-arrow-down:before {
  content: "\e904";
}
.icon-regular-epd-close:before {
  content: "\e905";
}
.icon-regular-epd-speech-bubble_questionmark:before {
  content: "\e906";
}
.icon-regular-epd-stop:before {
  content: "\e907";
}
.icon-regular-epd-info:before {
  content: "\e908";
}
.icon-regular-epd-header-arrow-left:before {
  content: "\e909";
}
.icon-regular-epd-euro-badge:before {
  content: "\e90a";
}
.icon-regular-epd-locate-me:before {
  content: "\e90b";
}
.icon-regular-epd-hint-favorite:before {
  content: "\e90c";
}
.icon-regular-epd-clock:before {
  content: "\e90d";
}
.icon-regular-epd-phone:before {
  content: "\e90e";
}
.icon-regular-epd-people:before {
  content: "\e90f";
}
.icon-regular-epd-paypal-lined:before {
  content: "\e910";
}
.icon-regular-epd-magnifier:before {
  content: "\e911";
}
.icon-regular-epd-envelope:before {
  content: "\e912";
}
.icon-regular-epd-creditcard:before {
  content: "\e913";
}
.icon-regular-epd-charging:before {
  content: "\e914";
}
.icon-regular-epd-arrow-right-bold:before {
  content: "\e915";
}
.icon-regular-epd-arrow-left-bold:before {
  content: "\e916";
}
.icon-regular-epd-arrow-down-bold:before {
  content: "\e917";
}
.icon-regular-epd-selected-dot:before {
  content: "\e918";
}
.icon-regular-epd-dot:before {
  content: "\e919";
}
