html,
body {
  background-color: $backgroundPrimaryColor;
  font-family: $fontBase, Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.light {
    color: $white;
  }
}

.fullWidth {
  width: 100%;
  padding: 0;
}

.half-view {
  max-height: 50vh !important;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: relative;
    bottom: 0%;
    left: 35px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 14px solid #f1f1f1;
  }
}

.margin-left-10n {
  margin-left: -10px;
}

.prio {
  padding-left: 0 !important;
  padding-right: 20px !important;
}

div:last-child.prio {
  padding-right: 10px !important;
}

.view-title {
  margin-bottom: $titleMargin;
  .title_icon {
    float: left;
    width: 40px;
  }

  .title {
    float: left;
    margin-left: 15px;
  }
}

/** Component Box **/
.component_box {
  background-color: $white;
  padding: 20px 10px 40px 10px;
  margin-top: 20px;
}
