form {
  label {
    + label {
      margin-top: 22px;
    }
    + input[type='checkbox'] + label,
    + input[type='radio'] + label {
      margin-top: 0;
    }
    ~ div + label,
    ~ small + label {
      margin-top: 22px;
    }
  }
}
