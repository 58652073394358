@font-face {
  font-family: 'icons_light';
  src:  url('/assets/fonts/icons_light/icons_light.eot?iqwtgl');
  src:  url('/assets/fonts/icons_light/icons_light.eot?iqwtgl#iefix') format('embedded-opentype'),
    url('/assets/fonts/icons_light/icons_light.ttf?iqwtgl') format('truetype'),
    url('/assets/fonts/icons_light/icons_light.woff?iqwtgl') format('woff'),
    url('/assets/fonts/icons_light/icons_light.svg?iqwtgl#icons_light') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-light-"], [class*=" icon-light-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons_light' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-light-computer-chip-core:before {
  content: "\e982";
}
.icon-light-arrow-left-x:before {
  content: "\e97f";
}
.icon-light-cog-3-up:before {
  content: "\e980";
}
.icon-light-clean-car-cog:before {
  content: "\e981";
}
.icon-light-clean-car-communication:before {
  content: "\e97e";
}
.icon-light-server-choose:before {
  content: "\e97d";
}
.icon-light-electric-car-cable-pilotsignal:before {
  content: "\e97a";
}
.icon-light-electric-car-cable-plugcharge:before {
  content: "\e97b";
}
.icon-light-clean-car-accu:before {
  content: "\e97c";
}
.icon-light-phone-type:before {
  content: "\e979";
}
.icon-light-expand-6:before {
  content: "\e977";
}
.icon-light-light-bulb-shine:before {
  content: "\e978";
}
.icon-light-add-circle:before {
  content: "\e975";
}
.icon-light-renewable-energy-battery-charge-1:before {
  content: "\e976";
}
.icon-light-wireless-payment-smartphone-1:before {
  content: "\e966";
}
.icon-light-wifi-lock:before {
  content: "\e967";
}
.icon-light-wall-socket:before {
  content: "\e968";
}
.icon-light-single-neutral-actions-add:before {
  content: "\e969";
}
.icon-light-office-outdoors:before {
  content: "\e96a";
}
.icon-light-monitor:before {
  content: "\e96b";
}
.icon-light-mobile-phone:before {
  content: "\e96c";
}
.icon-light-folder-dash-edit:before {
  content: "\e96d";
}
.icon-light-flash-drive:before {
  content: "\e96e";
}
.icon-light-flag-cash:before {
  content: "\e96f";
}
.icon-light-electric-car-cable:before {
  content: "\e970";
}
.icon-light-earth-1:before {
  content: "\e971";
}
.icon-light-credit-card-1:before {
  content: "\e972";
}
.icon-light-clean-car-ok:before {
  content: "\e973";
}
.icon-light-button-play:before {
  content: "\e974";
}
.icon-light-multiple-neutral-1:before {
  content: "\e965";
}
.icon-light-zip-file-upload:before {
  content: "\e900";
}
.icon-light-wifi-add:before {
  content: "\e901";
}
.icon-light-view-off:before {
  content: "\e902";
}
.icon-light-view-1:before {
  content: "\e903";
}
.icon-light-Unlock:before {
  content: "\e904";
}
.icon-light-time-clock-circle:before {
  content: "\e905";
}
.icon-light-text-underline:before {
  content: "\e906";
}
.icon-light-text-italic:before {
  content: "\e907";
}
.icon-light-text-bold:before {
  content: "\e908";
}
.icon-light-synchronize-arrow:before {
  content: "\e909";
}
.icon-light-synchronize-arrow-select:before {
  content: "\e90a";
}
.icon-light-stop-charging-battery:before {
  content: "\e90b";
}
.icon-light-smart-house-open:before {
  content: "\e90c";
}
.icon-light-single-neutral-actions:before {
  content: "\e90d";
}
.icon-light-shield-lock:before {
  content: "\e90e";
}
.icon-light-settings-slider:before {
  content: "\e90f";
}
.icon-light-search:before {
  content: "\e910";
}
.icon-light-question-circle:before {
  content: "\e911";
}
.icon-light-qr-code-scan:before {
  content: "\e912";
}
.icon-light-power-button:before {
  content: "\e913";
}
.icon-light-pin:before {
  content: "\e914";
}
.icon-light-pin-add:before {
  content: "\e915";
}
.icon-light-pencil-1:before {
  content: "\e916";
}
.icon-light-Paste:before {
  content: "\e917";
}
.icon-light-password-update:before {
  content: "\e918";
}
.icon-light-paragraph-right-align:before {
  content: "\e919";
}
.icon-light-paragraph-left-align:before {
  content: "\e91a";
}
.icon-light-paragraph-center-align:before {
  content: "\e91b";
}
.icon-light-paginate-filter-text:before {
  content: "\e91c";
}
.icon-light-paginate-filter-plus:before {
  content: "\e91d";
}
.icon-light-notes-add:before {
  content: "\e91e";
}
.icon-light-network-settings:before {
  content: "\e91f";
}
.icon-light-navigation-menu:before {
  content: "\e920";
}
.icon-light-monitor-graph-line:before {
  content: "\e921";
}
.icon-light-monetization-touch-coin:before {
  content: "\e922";
}
.icon-light-messages-bubble-add:before {
  content: "\e923";
}
.icon-light-maps-pin-1:before {
  content: "\e924";
}
.icon-light-logout:before {
  content: "\e925";
}
.icon-light-login-keys:before {
  content: "\e926";
}
.icon-light-login-3:before {
  content: "\e927";
}
.icon-light-list-edit:before {
  content: "\e928";
}
.icon-light-legal-scale:before {
  content: "\e929";
}
.icon-light-legal-certificate:before {
  content: "\e92a";
}
.icon-light-legal-certificate-add:before {
  content: "\e92b";
}
.icon-light-layout-module-1:before {
  content: "\e92c";
}
.icon-light-laptop-help-message:before {
  content: "\e92d";
}
.icon-light-italic-off:before {
  content: "\e92e";
}
.icon-light-information-circle:before {
  content: "\e92f";
}
.icon-light-headphones-customer-support-human:before {
  content: "\e930";
}
.icon-light-grid-ruler:before {
  content: "\e931";
}
.icon-light-gauge-dashboard:before {
  content: "\e932";
}
.icon-light-gauge-dashboard-1:before {
  content: "\e933";
}
.icon-light-folder-download:before {
  content: "\e934";
}
.icon-light-ecology-globe-hand:before {
  content: "\e935";
}
.icon-light-download-bottom:before {
  content: "\e936";
}
.icon-light-delete:before {
  content: "\e937";
}
.icon-light-delete-select:before {
  content: "\e938";
}
.icon-light-database-share_1:before {
  content: "\e939";
}
.icon-light-database-settings:before {
  content: "\e93a";
}
.icon-light-database-refresh:before {
  content: "\e93b";
}
.icon-light-database-lock:before {
  content: "\e93c";
}
.icon-light-database-add:before {
  content: "\e93d";
}
.icon-light-data-file-edit:before {
  content: "\e93e";
}
.icon-light-currency-dollar-bill:before {
  content: "\e93f";
}
.icon-light-currency-dollar-bill-add:before {
  content: "\e940";
}
.icon-light-credit-card-scan:before {
  content: "\e941";
}
.icon-light-credit-card-dollar-1:before {
  content: "\e942";
}
.icon-light-compass-1:before {
  content: "\e943";
}
.icon-light-common-file-text-download:before {
  content: "\e944";
}
.icon-light-cog:before {
  content: "\e945";
}
.icon-light-cog-hand-give:before {
  content: "\e946";
}
.icon-light-cog-hand-give-add:before {
  content: "\e947";
}
.icon-light-cog-3-add:before {
  content: "\e948";
}
.icon-light-close:before {
  content: "\e949";
}
.icon-light-clean-car-pay:before {
  content: "\e94a";
}
.icon-light-clean-car-gas:before {
  content: "\e94b";
}
.icon-light-check-double:before {
  content: "\e94c";
}
.icon-light-charging-flash-sync:before {
  content: "\e94d";
}
.icon-light-charging-battery-empty:before {
  content: "\e94e";
}
.icon-light-calendar-3:before {
  content: "\e94f";
}
.icon-light-business-deal-handshake:before {
  content: "\e950";
}
.icon-light-business-contract-handshake-sign:before {
  content: "\e951";
}
.icon-light-brain-lightning:before {
  content: "\e952";
}
.icon-light-bookmarks-document:before {
  content: "\e953";
}
.icon-light-book-close-bookmark-1:before {
  content: "\e954";
}
.icon-light-bin-1:before {
  content: "\e955";
}
.icon-light-arrow-up-1:before {
  content: "\e956";
}
.icon-light-arrow-right:before {
  content: "\e957";
}
.icon-light-arrow-right-end:before {
  content: "\e958";
}
.icon-light-arrow-right-1:before {
  content: "\e959";
}
.icon-light-arrow-left:before {
  content: "\e95a";
}
.icon-light-arrow-left-end:before {
  content: "\e95b";
}
.icon-light-arrow-left-1:before {
  content: "\e95c";
}
.icon-light-arrow-down-1:before {
  content: "\e95d";
}
.icon-light-analytics-graph-lines:before {
  content: "\e95e";
}
.icon-light-analytics-graph-bar:before {
  content: "\e95f";
}
.icon-light-alert-triangle:before {
  content: "\e960";
}
.icon-light-alarm-bell:before {
  content: "\e961";
}
.icon-light-accounting-document:before {
  content: "\e962";
}
.icon-light-accounting-document-add:before {
  content: "\e963";
}
.icon-light-ab-testing-browsers:before {
  content: "\e964";
}
